<template>
  <div class="jobs-container">
    <h1 class="title">{{ page.name }}</h1>
    <div class="butter-content jobs-page">
      <div
        class="page"
        v-for="(field, name, index) in page.fields"
        :key="index"
      >
        <div v-if="name === 'image_gallery'" class="gallery-item">
          <a
            :href="item.link"
            target="_blank"
            v-for="(item, index) in field.gallery_item"
            :key="index"
          >
            <img :src="item.image" :alt="item.caption" :title="item.caption" />
          </a>
        </div>
        <div
          class="page-content wysiwyg"
          v-html="field"
          v-if="name === 'wysiwyg'"
        ></div>
      </div>
    </div>
    <div class="jobs-list">
      <div class="jobs-list-item" v-for="(page, index) in pages" :key="index">
        <router-link :to="{ name: 'job', params: { slug: page.slug } }">{{
          page.name
        }}</router-link>
      </div>
    </div>
    <div class="reviewed-on">
      <div class="page-content reviewed-on">
        <p>Reviewed on: {{ page.fields.reviewed_on }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultLocale } from '@/constants/locales';
import { butter } from '@/buttercms';
import { sortBy } from 'underscore';

export default {
  name: 'Jobs',
  data() {
    return {
      title: 'Jobs',
      page: {
        slug: '',
        fields: {},
      },
      pages: [],
    };
  },
  methods: {
    fetchPage() {
      butter.page
        .retrieve('jobs-page', 'jobs', {
          locale: defaultLocale.code,
        })
        .then(res => {
          this.page = res.data.data;
        });
    },
    fetchPages() {
      butter.page.list('jobs', { locale: defaultLocale.code }).then(res => {
        this.pages = sortBy(res.data.data, 'name');
      });
    },
  },
  created() {
    this.fetchPage();
    this.fetchPages();
  },
};
</script>

<style lang="scss">
.content.jobs {
  min-height: auto;
}
</style>

<style scoped lang="scss">
::v-deep h1,
::v-deep h2 {
  margin-bottom: 0.5rem;
}
img {
  width: 100%;
}

.gallery-item {
  margin-bottom: 4rem;
}

.jobs-container {
  margin-bottom: 4rem;
  background: $white;
}

.page {
  margin-bottom: 1rem;
}
.page-content {
  display: flex;
  flex-direction: column;
}

.jobs-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.jobs-list-item {
  margin-bottom: 0.938rem;
  a {
    text-decoration: none;
    color: blue;
  }
}

.reviewed-on {
  margin-top: 4rem;
  color: grey;
}
</style>
